<template>
  <div :class="className" :style="{height:height,width:width}" title="本年度已完成任务"/>
</template>

<script>
import echarts from 'echarts'
import {GetTaskProgressChartData} from '../../../../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

const animationDuration = 3000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '330px'
    }
  },
  data() {
    return {
      chart: null,
      list: [],
    }
  },
  mounted() {
    var progress = [
    {
      name: '≥0%',
    },
    {
      name: '≥10%'
    },
    {
      name: '≥30%'
    },
    {
      name: '≥50%'
    },
    {
      name: '≥70%'
    },
    {
      name: '≥90%'
    },
    
    ]
    this.$nextTick(() => {
      this.initChart()
    })
    var user = JSON.parse(window.localStorage.user);
    GetTaskProgressChartData({userCode: user.sub}).then(res => {
         var valuelist = new Array()
         res.data.response.forEach(item => {
               let obj = {}
               obj.value = item;
               valuelist.push(obj);
         })
         this.list = valuelist.map((item,index) => {
            return {...item, ...progress[index]};
        });
          this.initChart()
    })

  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')

      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        radar: {
          radius: '66%',
          center: ['50%', '42%'],
          splitNumber: 8,
           name: {
            textStyle: {
                color: '#000000' // 文字颜色
            }
        },
          splitArea: {
            areaStyle: {
              color: 'rgba(127,95,132,.3)',
              opacity: 1,
              shadowBlur: 45,
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetX: 0,
              shadowOffsetY: 15
            }
          },
          indicator: [
            { name: '日常工作' },
            { name: '专项会议' },
            { name: '年度计划' },
            { name: '例会项目'},
          ]
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['≥0%','≥10%', '≥30%', '≥50%','≥70%','≥90%']
        },
        series: [{
          type: 'radar',
          symbolSize: 0,
          areaStyle: {
            normal: {
              shadowBlur: 13,
              shadowColor: 'rgba(0,0,0,.2)',
              shadowOffsetX: 0,
              shadowOffsetY: 10,
              opacity: 1
            }
          },
          data: 
          // this.list,
          [
            
            {
              value: [12, 55, 37, 88],
              name: '≥0%'
            },
            {
              value: [12, 13, 11, 15],
              name: '≥10%'
            },
            {
              value: [23, 22, 25, 26],
              name: '≥30%'
            },
            {
              value: [10, 10, 10, 10],
              name: '≥50%'
            },
            {
              value: [39, 56, 77, 32],
              name: '≥70%'
            },
            {
              value: [46, 67, 186, 25],
              name: '≥90%'
            }
          ],
          animationDuration: animationDuration
        }]
      })
    }
  }
}
</script>
