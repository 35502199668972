<template>
  <div class="dashboard-editor-container" style="background: #fff">
    <!-- <github-corner class="github-corner" /> -->

    <panel-group @handleSetLineChartData="handleSetLineChartData" />

    <!-- <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-chart :chart-data="lineChartData" />
    </el-row> -->
    <el-row :gutter="8">
      <el-col :xs="{span: 24}" :sm="{span: 24}" :md="{span: 24}" :lg="{span: 12}" :xl="{span: 12}" style="padding-right:8px;margin-bottom:30px;">
        <!-- <transaction-table /> -->
        <box-card />
      </el-col>
      <!-- <el-col  :sm="{span: 12}" :md="{span: 12}" :lg="{span: 6}" :xl="{span: 6}" style="margin-bottom:30px;border:1px solid #EDEDED;padding-left:0px;padding-right:0px;">
        <todo-list />
      </el-col> -->
      <el-col :xs="{span: 24}" :sm="{span: 24}" :md="{span: 24}" :lg="{span: 12}" :xl="{span: 12}" style="margin-bottom:30px;">
        <!-- <box-card /> -->
        <wait-read-table />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div >
          <span style="margin-left: 20px;color:#4169E1;font-weight: bold;font-size:15px;"><i class="el-icon-data-line"></i> 集团各单位任务录入使用概览</span>
          <el-divider></el-divider>
          <line-chart-company-task :style="{width:screenWidth-50+'px'}" />
        </div>
      </el-col>
    </el-row> 
    <el-row v-show="isShowLogchart" ><!--副部级以上可以查看部门人员的当天完成情况-->
      <el-col :span="24">
        <div >
          <span style="margin-left: 20px;color:#4169E1;font-weight: bold;font-size:15px;"><i class="el-icon-data-line"></i>中高层直属下级昨日任务概览</span>
          <el-divider></el-divider>
          <log-bar-chart :style="{width:screenWidth-50+'px'}" />
        </div>
      </el-col>
    </el-row>   
    <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <span style="margin-left: 20px;color:#4169E1;font-weight: bold;font-size:15px;"><i class="el-icon-pie-chart"></i> 任务进度占比分析</span>
           <el-divider></el-divider>
          <raddar-chart />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <span style="margin-left: 20px;color:#4169E1;font-weight: bold;font-size:15px;"><i class="el-icon-pie-chart"></i> 任务类型数量占比分析</span>
           <el-divider></el-divider>
          <pie-chart />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <span style="margin-left: 20px;color:#4169E1;font-weight: bold;font-size:15px;"><i class="el-icon-pie-chart"></i> 本周任务分布数量占比分析</span>
           <el-divider></el-divider>
          <bar-chart />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import GithubCorner from '@/components/GithubCorner'
import PanelGroup from './components/PanelGroup'
import LineChart from './components/LineChart'
import RaddarChart from './components/RaddarChart'
import PieChart from './components/PieChart'
import BarChart from './components/BarChart'
import LogBarChart from './components/LogBarChart'
import LineChartCompanyTask from './components/LineChartCompanyTask'
import WaitReadTable from './components/IndexWaitReadTable'
import TodoList from './components/TodoList'
import BoxCard from './components/BoxCard'
import {GetPostDepartmentOrganizationViewAllByUserId} from '../../../api/oa'

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
}

export default {
  name: 'DashboardAdmin',
  components: {
    // GithubCorner,
    PanelGroup,
    LineChart,
    RaddarChart,
    PieChart,
    BarChart,
    LogBarChart,
    LineChartCompanyTask,
    WaitReadTable,
    TodoList,
    BoxCard
  },
  data() {
    return {
      isShowLogchart:false,
      lineChartData: lineChartData.newVisitis,
      screenWidth: document.body.clientWidth
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type]
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted() {
    this.$router.addRoutes([
      {
        path: "/Task/WaitDoTask",
        name: "WaitDoTask",
        component: () => import("../../Task/WaitDoTask"),
      },
      {
        path: "/Task/WaitReadTask",
        name: "WaitReadTask",
        component: () => import("../../Task/WaitReadTask"),
      },
      {
        path: "/Task/WaitApproval",
        name: "WaitApproval",
        component: () => import("../../Task/WaitApproval"),
      },
      {
        path: "/Task/ComplatedTask",
        name: "ComplatedTask",
        component: () => import("../../Task/ComplatedTask"),
      },
      {
        path: "/Task/TaskManage",
        name: "TaskManage",
        component: () => import("../../Task/TaskManage"),
      },
      {
        path: "/Task/MyCollectTask",
        name: "MyCollectTask",
        component: () => import("../../Task/MyCollectTask"),
      },
    ]);

    let self=this;
    var user = JSON.parse(window.localStorage.user);
    GetPostDepartmentOrganizationViewAllByUserId({userId: user.sub,onlyPrincipal:true}).then(res => {
      // console.log(res)
      if(res.data.code==200)
      {
        if(res.data.data.length>0)
        {
          var d=res.data.data[0];
          // console.log(d);
          if(d.jobLevelValue<=9)
          {
            //展示
            this.isShowLogchart=true;
          }
        }
      }
    })

    // if (this._isMobile()) {
    //   console.log("手机端");
    //   this.$router.replace('/MyHome');
    // } else {
    //   console.log("pc端");
    //   // this.$router.replace('/pc_index');
    // }
  },

  
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    // padding: 16px 16px 0;
    margin-bottom: 32px;
    border:1px solid #EDEDED
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
<style scoped>
/deep/.el-divider{
     margin: 1px 0;
     background: 0 0;
     border-top: 1px dashed #e8eaec;
 }
</style>
